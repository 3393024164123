import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from "./link";

const BoxWebinar = ({ data, position }) => {
  return (
    <article className={`box-post${position === 0 ? " box-post--big" : " d-flex flex-column"}`}>
      <Link to={`/webinar/${data.link}/`} title={data.title} className="box-post__link">
        <span className="invisible">Leggi l'articolo: {data.title}</span>
      </Link>
      <div className="box-post__img-wrap">
        <GatsbyImage
          image={getImage(data.image)}
          title={data.image.title}
          alt={data.image.description || data.image.title}
          className="box-post__img"
          loading="eager"
        />
        {/* {data.category && <div className="box-post__category">{data.category}</div>} */}
      </div>
      <div className="box-post__wrap-text w-100">
        <div className="box-post__title">{data.title}</div>
        <div className="d-flex justify-content-between align-items-center">
          {data.date && <div className="box-post__date">{data.date}</div>}
          <div className="box-post__btn mt-auto">
            Scopri <span>di più</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default BoxWebinar;
